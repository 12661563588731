import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { startWith } from 'rxjs'
import { MatMenuTrigger, MenuPositionY } from '@angular/material/menu'
import { StoreService } from '../../services/store/store.service'
import { UserService } from '../../services/user/user.service'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

interface SelectorLang {
  slug: 'en' | 'sv' | 'es'
  name: 'english' | 'swedish' | 'spanish'
}

@Component({
  selector: 'shared-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, AfterViewInit {
  @Input() position: MenuPositionY = 'below'
  @Input() panelClass: string = null
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | Partial<MatMenuTrigger>

  public defaultLang: 'en' | 'sv' | 'es' = 'en'
  public ngxLangugages: SelectorLang[] = []
  public user: any
  public isLoading = false
  isDesktop: boolean = false;

  constructor (
    public storeService: StoreService,
    private translateService: TranslateService,
    public userService: UserService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngAfterViewInit (): void {
    this.ngxLangugages = this.translateService.getLangs().map(
      data => {
        return {
          slug: data,
          name: this.getLangName(data)
        } as SelectorLang
      }
    )

    this.cdr.detectChanges()
  }

  ngOnInit (): void {
    this.storeService.user$
      .pipe(
        startWith({ id: 0, language: 'en' })
      )
      .subscribe(u => {
        this.user = u
      })

      this.breakpointObserver.observe(['(min-width: 800px)']).subscribe((state: BreakpointState) => {
        this.isDesktop = state.matches;
      });
  }

  selectLang (language: 'en' | 'sv' | 'es') {
    this.isLoading = true

    this.userService.updateUserData({ language })
      .subscribe({
        next: () => {
          this.isLoading = false
        },
        error: () => {
          this.isLoading = false
        }
      })
  }

  private getLangName(language: string): string {

    console.log('lang is', language)
    switch (language) {
      case 'en':
        return _('english')
      case 'sv':
        return _('swedish')
      case 'es':
        return _('spanish')
      default:
        throw new Error('Unsupported language code')
    }
  }
  
}
